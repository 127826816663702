.request_list {
  width: 100%;
  .inline-block {
    display: inline-block;
    float: left;
  }
  .request-item {
    padding: 15px 20px;
    height: 50px;
    font-size: 18px;
    line-height: 20px;
    position: relative;
    &:hover {
      opacity: 0.8;
    }
    i {
      opacity: 0.6;
      font-size: 14px;
      line-height: 20px;
      &.fa-star {
        padding-left: 6px;
        color: $yellow;
      }
    }
    .name {
      font-size: 14px;
      color: $white;
      line-height: 20px;
      opacity: 0.6;
    }
    .timeago {
      font-size: 13px;
      float: right;
      text-align: right;
      opacity: 0.6;
      color: $white;
      &.red {
        opacity: 1;
        color: $color_7;
      }
    }
    &.active {
      opacity: 1;
      background-color: $frostbluedark;
      i, .name, .timeago {
        opacity: 1;
      }
      .star_info {
        display: inline-block;
      }
    }
  }
  i.message-important {
    color: $white;
    padding-right: 8px;
  }
  a.request-item-linkblock {
    position: absolute;
    left: 0;
    right: 0;
    bottom:0;
    top:0;
    width: 100%;
    height: 100%;
  }
}

table.request_list {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0 0 45px 0;
  border-spacing: 0;
  td {
    padding: 10px;
    margin: 0;
    &:first-child {
     padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
    &.timeago {
      color: $secondarytext;
    }
    &.red {
      color: $color_7;
      a {
        color: $color_7;
      }
      small {
        color: $color_7;
      }
    }
    small {
      min-width: 55px;
      font-size: 12px;
      color: $color_5;
      font-family: $font_family_2;
    }
    i {
      color: $color_6;
    }
  }

  td.active {
    .star_info {
      display: inline-block;
    }
  }
  td.numeral {
    text-align: center;
    font-size: 12px;
    color: $color_5;
    font-family: $font_family_2;
  }
  td.name {
    max-width: 80%;
    font-size: 18px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
