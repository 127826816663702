.general-page {
  width: 100%;
  padding: 100px 0 0 0;
  h2 {
    text-align: center;
    margin: 30px 0 60px 0;
  }
}
.change_avatar {
  position: relative;
  padding: 0;
  text-align: center;
  width: 80px;
  margin:auto;
  display: block;
  margin-bottom: 20px;
  figure {
    width: 80px;
    height: 80px;
    padding: 0;
    margin: 0 auto 0 auto;
    overflow: hidden;
    display: inline-block;
    border-radius: 50%;
    background: $lightgrey;
    border: 1px solid $bordercolor;
    img {
      width: 100%;
      height: auto;
    }
  }
  .el-button {
    width: 40px;
    height:40px;
    padding: 0;
    line-height: 40px;
    position: absolute;
    right:-10px;
    bottom:-10px;
    text-align: center;
  }
}
.admin_button {
  color: $color_4;
  background: $color_8;
  border: 1px solid $color_4;
  border-radius: 4px;
  margin: 25px auto;
  padding: 8px 20px;
  font-size: 15px;
  &:hover {
    color: $color_8;
    background: $color_4;
  }
}
.account-settings {
  width: 100%;
  td {
    padding: 5px 0;
    color: $color_1;
  }
  .input-line-height,
  label {
    line-height: 40px;
  }
  input {
    padding: 8px 10px;
    width: 100%;
    font-size: 15px;
    color: $color_1;
    margin: 0 0 10px 0;
    border-radius: 3px;
  }
  .form_field {
    position: relative;
    i {
      position: absolute;
      color: $color_21;
      font-size: 21px;
      top: 25px;
      right: 25px;
    }
  }

}
.language-tag  {
  height: 29px;
  float: left;
  padding: 0 10px;
  margin: 3px 3px 5px 0;
  font-size: 14px;
  color: $almostblack;
  line-height: 27px;
  border: 1px solid $bordercolor;
  border-radius: 3px;
  i {
    margin: 0 0 0 7px;
  }
  &:hover {
    color: $white;
    background: $color_3;
    border-color: none;
  }
}
.privacy {
  padding: 15px;
  text-align: center;
}

.statistics {
  width: 100%;
  padding: 73px 0 0 0;
  h2 {
    margin: 30px 0;
    span {
      color: $color_15;
      font-size: 24px;
      text-transform: uppercase;
      padding-left: 15px;
    }
  }
}
.filters {
  width: 100%;
  height: 38px;
  font-size: 15px;
}
.stats-input {
  width: 50%;
  height: 38px;
  font-size: 15px;
}
.panel {
  padding: 10px 15px;
  border: 1px solid $color_9;
  border-radius: 7px;
  position: relative;
  text-align: center;
  color: $color_3;
  font-size: 55px;
  line-height: 1.8em;
  h4 {
    font-size: 30px;
    color: $color_15;
    margin: 0;
  }
  i {
    display: block;
    font-size: 50px;
    line-height: 1.0em;
  }
}
.panel-alt {
  color: $color_21;
  h4 {
    color: $color_15;
  }
}

.chart {
  padding: 20px;
  overflow: hidden;
  float: left;
  background: #fffcf4;
  width: 100%;
  h2 {
    margin: 0 0 20px 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #ccc;
    color: $color_15;
    display: block;
    width: 100%;
  }
}
.progress-bar {
  float: left;
  height: 300px;
  width: 80px;
  margin-right: 25px;
}

.progress-track {
  position: relative;
  width: 80px;
  height: 100%;
  background: #ebebeb;
  text-align: center;
  span {
    text-transform: uppercase;
    font-size: 12px;
  }
}

.progress-fill {
  position: relative;
  background: #00ceff;
  height: 60%;
  width: 80px;
  color: #fff;
  text-align: center;
  font-family: "Lato","Verdana",sans-serif;
  font-size: 12px;
  line-height: 20px;
}
.time {
  text-transform: uppercase;
  font-size: 12px;
  span {
    padding: 0 10px;
  }
}
.success {
  background-color: #28a745;
  height: 40px;
  padding-top: 10px;
  padding-left: 10px;
  color: white;
  border-radius: 5px;
}

#change-password-form .alert.alert-danger p {
  padding: 5px 0px;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  text-decoration: solid;
  font-weight: bold;
  font-family: "Lato","Verdana",sans-serif;
}

div {
  .error-wrapper {
    padding: 8px;
    background: $red;
    display: block;
    margin: 8px 0;
    border-radius: 4px;
  }
  .error {
    color: white;
    text-decoration: solid;
    font-weight: bold;
    font-size:14px;
    font-family: 'Proxima-Nova-Bold', sans-serif;
    > p {
      padding: 0px!important;
      line-height: 0px!important;
      text-align: left!important;
    }
  }
}

