.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: $background_color_2;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 50px;
  transition: 0.3s;
  .closebtn {
    width: 30px;
    height: 30px;
    line-height: 25px;
    color: $color_8;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 50px;
    margin-left: 0;
    &:hover {
      color: $color_4;
    }
  }
}

ul.nav {
  padding: 75px 0 25px 25px;
  li {
    margin: 0;
    font-size: 21px;
    line-height: 2em;
    font-weight: 600;
    display: block;
    a {
      color: $white;
      padding: 0 0 0 10px;
    }
    &:hover {
      padding: 0 0 0 12px;
      transition: .1s;
    }
  }
}
