//June
$color_1: #000;
$color_2: #333;
$color_3: #0075be;
$color_4: #95b3c6;
$color_5: #515456;
$color_6: #ffb100;
$color_7: #ff8042; //#fe2c00;
$color_8: #ffffff;
$color_9: #d1dfe7;
$color_10: #71838e;
$color_11: #bbc7ce;
$color_12: #fff;
$color_13: #cacccc;
$color_14: #a4a5a5;
$color_15: #72838e;
$color_16: #515355;
$color_17: #dbdbdb;
$color_18: #0f7dc1;
$color_19: #94b3c5;
$color_20: #b5cad7;
$color_21: #00a6ce;
$font_family_1: 'Proxima-Nova', sans-serif;
$font_family_2: 'Proxima-Nova-Bold', sans-serif;
$font_family_3: 'Proxima-Nova-Semibold', sans-serif;
$background_color_1: #e4ebf0;
$background_color_2: #0075be;
$border_color_1: #0075be;
$border_color_2: rgba(248, 240, 221, 0);
$border_color_3: rgba(209, 223, 231, 0);
$border_top_color_1: #f8f0dd;
$border_top_color_2: #d1dfe7;
$border_top_color_3: #d7f2e1;

/* Element */
$almostblack: #333333;

$white: #ffffff;
$frostblue: #0D76D8; //#F2F8FD;
$frostbluedark: #086ECE; //#E9F3FC;
$frostbluelight: #6687BF;
$bordercolor: #EEEEEE;
$regulartext: #828282; //#707E95;
$grey: #5f5f5f;
$icongrey: #C8CED5;
$lightestgrey: #fafafa;
$lightgrey: #B7BCC2;
$secondarytext: $icongrey;//#A0A5BC;
$midgrey: #999999;
$red: #fa5555;
$success: #1BBC53;
$yellow: #ffcf42;

$leftpanelbg: $frostblue;
$leftpanelborder: $frostbluedark;
$leftpanelactive: $frostbluelight;