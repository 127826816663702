.k-autocomplete, .k-flatcolorpicker .k-color-value, .k-combobox .k-dropdown-wrap, .k-datepicker .k-picker-wrap, .k-timepicker .k-picker-wrap, .k-datetimepicker .k-picker-wrap, .k-dateinput .k-dateinput-wrap, .k-textbox, .k-textarea, .k-input.k-textbox, .k-multiselect-wrap, .k-numerictextbox .k-numeric-wrap {
  border-color: $bordercolor;
  border-radius: 4px;
}

.k-input {
  &::placeholder { /* Edge */
    color: $secondarytext;
  }
}
.k-dropdown .k-dropdown-wrap.k-state-focused, .k-autocomplete.k-state-focused, .k-autocomplete.k-state-active, .k-combobox>.k-state-focused, .k-combobox .k-state-active, .k-datepicker .k-state-focused, .k-datepicker .k-state-active, .k-timepicker .k-state-focused, .k-timepicker .k-state-active, .k-datetimepicker .k-state-focused, .k-datetimepicker .k-state-active, .k-dateinput .k-state-focused, .k-state-focused>.k-multiselect-wrap, .k-numerictextbox .k-state-focused {
  box-shadow: none!important;
}
.k-autocomplete .k-input, .k-dateinput-wrap .k-input, .k-dropdown-wrap .k-input, .k-picker-wrap .k-input, .k-multiselect-wrap .k-input, .k-numeric-wrap .k-input {
  height: 38px!important;
  padding: 0 15px!important;
  &::selection {
    background-color: #ccc;
    color: #5a5e66;
  }
}
.k-datepicker {
  .k-picker-wrap {
    position: relative;
    .k-input {
      padding-left: 40px!important;
    }
  }
  .k-select {
    position: absolute;
    height:38px;
    top:0;
    line-height: 38px;
    display: block!important;
    width: 100%!important;
    left: 15px;
    text-align: left!important;
    right:0;
    background-color: transparent!important;
    background-image: none!important;
  }
}
.k-calendar .k-content .k-today,
.k-icon.k-i-calendar {
  color: #5a5e66!important;
}
.k-calendar .k-state-selected .k-link {
  border-color: #0075be!important;
  background-color: #0075be!important;
}
.k-calendar .k-footer .k-nav-today, .k-calendar .k-calendar-header .k-today {
  color: #0075be!important;
  &:hover {
    color: #00a6ce!important;
  }
}
kendo-searchbar.k-searchbar ::placeholder {
  color: blue;
  opacity: 1;
}