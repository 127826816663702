/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $icongrey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $frostblue;
}

::-webkit-input-placeholder { /* Edge */
  color: $secondarytext;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $secondarytext;
}

::placeholder {
  color: $secondarytext;
}


.el-input__inner {
  border-color: $bordercolor;
}

.bg-white {
  background: $white;
}
.bg-frostblue {
  background: $frostblue;
}
.mt-0 {
  margin-top: 0;
} 
.mb-3 {
  margin-bottom: 30px;
}
.mr-6 {
  margin-right: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.form-group {
  margin-bottom: 14px;
}

.text-lightgrey {
  color: $lightgrey;
}

.text-black {
  color: $almostblack;
}
.text-danger {
  color: $red;
}
.text-success {
  color: $success;
}
.text-small {
  font-size: 14px;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.floatNone {
  float: none;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.text-center,
.textCenter {
  text-align: center;
}
.padding-b-25 {
  padding-bottom: 25px;
}
.blue {color: $color_3;}

.absolute-v-center {
  top: 50%;
  margin: 0;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
 }


body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  color: $regulartext;
  font-family: $font_family_1;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background: $leftpanelbg;
}
a {
  outline: none !important;
  text-decoration: none;
  -webkit-transition: all .6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
  -ms-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
  color: $color_3;
  &:hover {
    outline: none;
    text-decoration: none;
    color: $color_21;
  }
  &:focus {
    outline: none;
    text-decoration: none;
    color: $color_21;
  }
}
select {
  &:focus {
    outline: none;
    text-decoration: none;
  }
  outline: none !important;
  font-family: $font_family_1;
}
button {
  &:focus {
    outline: none;
    text-decoration: none;
  }
  outline: none !important;
  font-family: $font_family_1;
}
input {
  height: 28px;
  padding: 0 8px;
  font-size: 14px;
  color: $grey;
  border: 1px solid $bordercolor;
  border-radius: 4px;
}
section.ptb-5 {
  padding-top: 50px;
  padding-bottom: 50px;
}
* {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  &:before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  &:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}

/*Cleafix*/
.clearfix:before,
.clearfix:after, .clear:before, .clear:after {
    content: " ";
    display: table;
}
.clearfix:after, .clear:after {
    clear: both;
}
/* For IE 6/7 only */
.clearfix, .clear {
    *zoom: 1;
}

.clearfix {
  clear: initial;
}

img {
  border: none;
  vertical-align: middle;
  max-width: 100%;
}
li {
  list-style: none;
}

input, textarea {
  outline: none !important;
  font-family: $font_family_1;
}

.clear {clear: both;}
.small {
  font-size: 12px;
  font-family: $font_family_2;
  color: $color_15;
  float: right;
  padding: 0 10px;
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}



