header {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
}
.topbar {
  width: 100%;
  height: 78px;
  float: left;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  .el-row {
    .el-col {
      &:first-child {
        padding-left:20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
  #top-white {
    padding: 19px 20px;
    background: $white;
    border-bottom: 1px solid $bordercolor;
    .actions {
      .el-button {
        height:40px;
      }
    }
  }
  #top-frostblue {
    padding: 20px;
    background: $leftpanelbg;
    border-bottom: 1px solid $leftpanelbg;
  }
  .client-details {
    float: left;
    height: 44px;
    margin-left: 12px;
  }
  .client-name {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    font-family: $font_family_3;
  }
  .user-details {
    line-height: 20px;
    font-size: 14px;
    a, a:visited, a:hover, a:focus {
      color: $white;
      > span {
        opacity: 0.6;
      }
    }
    a:hover {
      > span {
        opacity: 1;
      }
    }
    i.fa {
      line-height: 20px;
    }
  }
}
.nav_btn {
  float: left;
  min-height: 73px;
  padding: 0 20px;
  margin: 0;
  font-size: 38px;
  color: $color_3;
  line-height: 73px;
  cursor: pointer;
}

.logo {
  float: left;
  width: 44px;
  height: 44px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  img {
    width: 44px;
    height: auto;
  }
}
.client-logo {
  max-width: 200px;
  height: auto;
  display: block;
}

.search_input {
  position: relative;
  min-width: 280px;
  margin-right: 10px;
  .k-dropdown-wrap .k-input,
  .el-input__inner {
    padding-left: 40px!important;
  }
  .k-dropdown-wrap .k-input {
    display: block;
  }
  .el-input__suffix {
    right:initial;
    left:5px;
    z-index: 1000;
  }
  .k-combobox {
    border-radius: 4px;
    border: 1px solid $bordercolor;
    width: 100%;
    .k-icon {
      line-height: 38px!important;
      height:38px!important;
      &.k-clear-value {
        top: 0!important;
        right:8px;
      }
    }
  }
}
.search_info_alt {
  width:  66.66666667%;;
}
.filter_info {
  width: 33.33333333%;
  float: left;
  padding: 0;
  margin: 11px 0 0 0;
  position: relative;
  i {
    padding: 0;
    margin: 0;
    font-size: 25px;
    color: $color_4;
    position: absolute;
    right: 15px;
    top: 12px;
    z-index: 1;
  }
  .field {
    width: 100%;
    height: 51px;
    float: left;
    padding: 0 55px 0 15px;
    margin: 0;
    font-size: 18px;
    color: $color_4;
    border: 1px solid #d1dfe7;
    border-radius: 0 4px 4px 0;
  }
}
.client-phone {
  font-size:20px;
  display: block;
  padding:15px;
  border-bottom: 1px solid $bordercolor;
}
.topbar.sticky {
  position: fixed;
  width: 100%;
  text-align: left;
}
section {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
}
.left_height {
  height: 100%;
  width: 100%;
  background: $leftpanelbg;
}
.left_bar {
  width: 100%;
  float: left;
  padding-top: 14px;
  margin: 0;
  h6 {
    margin-left: 20px;
    color: $white;
  }
}