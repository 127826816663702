@import 'base/variables';

/*Below is only used if there is a business, if not remove */
//Update Element button primary color
$elementPrimary: #409EFF;

.el-button--primary {
  color: #fff!important;
  background-color: $elementPrimary!important;
  border-color: $elementPrimary!important;
}


//Changing the lightest blue to neutral greys to match whatever business's primary color
$leftpanelbg: $frostblue;
$leftpanelborder: $frostbluedark;
$leftpanelactive: $frostbluelight;

@import 'main';
