

.el-button {
    text-transform: uppercase;
    color: $almostblack;
    border: 0;
    &.green {
        background-color: $success;
        color: $white;
        &:hover {
            background-color: lighten($success, 4%);
        }
    }
    &.el-button--success {
        background-color: $success;
        border-color: $success;
        color: $white;
        &:hover {
            background-color: lighten($success, 4%);
        }
    }
    &.is-plain {
        border-color: $white;
        &:hover {
            color: $almostblack;
            border-color: transparent;
        }
    }
    &.el-button.is-disabled, &.el-button.is-disabled:focus, &.el-button.is-disabled:hover,
    &.el-button.is-disabled.is-plain, &.el-button.is-disabled.is-plain:focus, &.el-button.is-disabled.is-plain:hover {
        color: $lightgrey;
        border-color: transparent;
        background-color: $lightestgrey;
        opacity: 0.6;
    }
}

.el-textarea.is-disabled .el-textarea__inner {
    background-color: $white;
    opacity: 0.5;
}