.guest_header {
  height: 40px;
  > span {
    display: block;
    span {
      display: inline-block;
      margin-right: 10px;
    }
    &.guest-name {
      font-size: 18px;
      font-family: $font_family_2;
      font-weight: 600;
      line-height: 22px;
      color: $almostblack;
    }
    &.guest-subdetails {
      line-height: 18px;
      color: $lightgrey;
      font-size: 15px;
    }
  }

}

#messages {
  position: relative;
  padding-right: 0;
  padding-left: 0;
}

#right-panel,
#message-area {
  height:100vh;
  position: relative;
  background: $white;
  margin-top: -79px;
}
.cmt_block {
  width: 100%;
  position: absolute;
  z-index: 999;
  padding: 15px;
  background: $white; // $lightestgrey;
/*  border-top: 1px solid $bordercolor;*/
  left: 0;
  right: 0;
  bottom:0;
  width: 100%;
  .cmt_box {
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    .icon {
      float: left;
      padding: 0;
      margin: 0;
      position: absolute;
      left: 15px;
      top: 12px;
    }
  }
  .field {
    width: 100%;
    padding: 5px 0;
    padding-right: 100px;
    margin: 0;
    color: $color_5;
    background: $white;
    border: 1px solid $bordercolor;
    border-radius: 5px 5px 0 0;
    font-size: 15px;
    small {
    }
    textarea {
      width: 100%;
      border: 0;
      font-size: 16px;
      height: 40px;
    }
  }
  .send-btn {
    position: absolute;
    top:14px;
    width: 78px;
    right: 15px;
    z-index:10;
    padding: 12px 4px;
  }
  .cmt_btn {
    width: 40px;
    height:40px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 100px;
    top: 14px;
    z-index:10;
    a {
      text-align: center;
      color: $lightgrey;
      width:40px;
      height: 40px;
      display: block;
      line-height: 40px;
      border-radius: 4px;
      &:hover {
        color: $frostblue;
      }
      /*
      width: 35px;
      min-height: 25px;
      display: block;
      padding: 5px 0 0 5px;
      margin: 0;
      color: @color_9;
      text-align: center;
      border-left: 1px solid #d1dfe7;
      */
    }
  }
  .cmt_inner {
    padding: 0;
    margin: 0;
    position: relative;
  }
}
ul.jq-dropdown-menu {
  border:0!important;
  border-radius: 6px!important;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1)!important;
  padding:0!important;
  li {
    color: $midgrey!important;
    &:last-child {
      border-bottom: none;
    }
    > a {
      color: $midgrey!important;
      padding: 15px!important;
    }
  }
}


.jq-dropdown.jq-dropdown-tip {
  left: 70px!important;
  &::after,
  &::before {
    display: none!important;
  }
}
#jq-dropdown-3 {
  position: absolute;
  top:0!important;
  margin-top: -200px;
  border-radius: 64px;
  right: 15px!important;
}


.template-select {
  background: $white;
  border: solid 1px $lightestgrey;
  border-radius: 0px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: visible;
  padding: 5px;
  margin: 0;
  font-size:14px;
  width: 100%;
  .content {
    height: 150px;
  }
  ul {
    li {
      display: block;
      padding: 6px 4px;
      margin: 0;
    }
  }
}
.cmt_detail_2 {
  width: 100%;
  min-height: 30px;
  padding: 0;
  margin: 0;
  background: $lightestgrey;
  border: 1px solid $bordercolor;
  border-top: none;
  border-radius: 0 0 5px 5px;
  .priority {
    float: left;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
    border-right: 1px solid #d1dfe7;
    .btn {
      padding: 0 10px;
      margin: 0;
      color: $color_10;
      line-height: 35px;
      i {
        float: right;
        padding: 0;
        margin: 0;
        color: $color_11;
        line-height: 35px;
      }
    }
  }
}
.cmt_detail_3 {
  width: 100%;
  min-height: 35px;
  float: left;
  padding: 0 0 0 4px;
  margin: 0;
  border: 1px solid #a6a6a6;
  border-radius: 5px;
}
.tag_info {
  float: left;
  height: 30px;
  padding: 0;
  margin: 0;
  color: $midgrey;
  i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    float: left;
  }
  input {
    -webkit-appearance: none;
    border: none;
    font-size: 13px;
  }
}

.cmt_done_info {
  float: right;
  margin: 0;
  font-size: 14px;
  span {
    margin-left: 30px;
    padding: 0 15px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    /*
    padding: 0;
    margin: 0 10px 0 0;
    color: @color_13;
    font-weight: 600;
    line-height: 35px;
    strong {
      color: @color_14;
      font-weight: 600;
    }*/
  }
  .done_btn {
    height: 25px;
    padding: 0 20px;
    margin: 0;
    display: inline-block;
    font-size: 14px;
    color: $color_8;
    font-weight: 600;
    border: none;
    border-radius: 3px;
    background: #0075be;
    cursor: pointer;
    &:hover {
      color: $color_8;
      background: #1a93de;
    }
  }
}


.message_group {
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #d1dfe7;
  span {
    margin: 0 0 5px 0;
    font-size: 12px;
    color: $color_15;
    font-family: $font_family_2;
  }
}

.right_details {
  width: 100%;
  padding: 0;
  height:100vh;
  padding-top: 79px;
  font-size: 14px;
  overflow-y: scroll;
  border-left: 1px solid $bordercolor;
}


.cmt_list {
  width: 100%;
  padding: 0;
  height: calc(100vh - 132px);
  max-height: calc(100vh - 194px);
  margin: 0;
  margin-top: 79px;
  position: relative;
  .request-divider {
    font-family: $font_family_3;
    text-align:center;
    position: relative;
    line-height: 30px;
    height:30px;
    position: sticky;
    z-index:99;
    top:0;
    background: $white;
    /*
    border-bottom: 1px solid $bordercolor;
    border-top: 1px solid $bordercolor;*/
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      background-color: $bordercolor;
      top:15px;
      left:0;
      right:0;
      z-index: -1;
    }
    span {
      font-size: 13px;
      color: $lightgrey;
      background: $white;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      padding: 0 4px;
      margin: auto;
      line-height: 30px;
      height:30px;
    }
    &:after {
      /*
      content:"";
      display: block;
      position: absolute;
      left:0;
      right:0;
      height:1px;
      z-index: -1;
      top: 14px;
      background: @bordercolor;
      */
    }
  }



  .block {
    position: relative;
    padding: 20px;
    padding-left: 74px;
    margin: 0;
    .currentLanguage {
      color: $lightgrey;
      font-size: 12px;
    }
    .el-collapse {
      border-top:0;
    }
    .el-collapse-item__header {
      color: $color_3;
      height: 30px;
      line-height: 30px;
      border-bottom: 0;
      display: inline-block;
      border-top: 0;
      position: relative;
      padding-right: 13px;
      font-size: 12px;
      .el-collapse-item__arrow {
        line-height: 30px;
        position: absolute;
        right: 0;
        float: none;
        margin-right: 0;
      }
    }
    .el-collapse-item__wrap {
      border: 1px solid $bordercolor;
      border-radius: 6px;
    }
    .el-collapse-item__content {
      padding-bottom: 0;
      padding: 10px;
    }
    img.via-icon {
      position: absolute;
      top: 46px;
      width: 16px;
      height:16px;
      left:48px;
    }
    figure {
      width: 44px;
      height: 44px;
      padding: 0;
      margin: 0;
      position: absolute;
      left: 20px;
      top: 20px;
      overflow: hidden;
      border-radius: 100%;
      border: 1px solid $bordercolor;
      img {
        display: block;
        width: 100%;
        height:auto;
      }
    }
    .top_info {
      h6 {
        color: $almostblack;
        float: left;
        text-transform: none;
        font-family: $font_family_3;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        display: inline-block;
        margin-right: 8px;
      }
      span {
        font-size:12px;
        line-height: 18px;
        margin:0 6px;
        float: left;
        display: inline-block;
        color: $lightgrey;
          &.timestamp {
            color: $lightgrey;
          }
      }

      /*
      .key_info {
        float: left;
        padding: 0;
        font-size: 14px;
        color: @color_15;
        font-weight: 600;
        i {
          margin-right: 3px;
        }
      }*/
    }
    .block-message {
      line-height: 1.4;
      font-size: 14px;
    }
    .block-tags {
      margin-top: 4px;
      span.displayTag {
        float: left;
        width: 20px;
        line-height: 28px;
        color: $lightgrey;
      }
      .service_tag {
        height: 28px;
        background-color: #1BBC53;
        border-color: #1BBC53;
        color: #ffffff;
      }
    }
    .detail_2 {
      width: 100%;
      float: left;
      padding: 0;
      margin: 0;
      .language_info {
        float: right;
        padding: 0;
        margin: 0;
        select {
          padding: 6px 10;
          color: $color_15;
          font-size: 15px;
        }
      }
      .busy_info {
        float: left;
        padding: 0;
        margin: 5px 15px 0 0;
        font-size: 12px;
        color: $color_7;
      }
      .tag_info {
        float: left;
        padding: 0;
        margin: 0 10px 0 0;
        font-size: 13px;
        color: $color_15;
        i {
          margin-right: 5px;
        }
      }
    }
  }

  .block.block_2 {

  }
}
.right_space {
  padding-right: 0;
}
.left_space {
  padding-left: 0;
}


.guest_detail {
  .k-multiselect.k-state-disabled .k-multiselect-wrap {
    border-color: transparent;
    .k-button .k-select {
       display: none;
     }
  }
  h3 {
    width: 100%;
    padding: 14px 20px;
    padding-bottom: 8px;
    margin: 0;
    font-size: 13px;
    line-height: 22px;
    color: $almostblack;
    font-family: $font_family_2;
    font-weight: 600;
    background: $white;
    text-transform: uppercase;
    position: relative;
    .right-actions {
      position: absolute;
      right:0;
      top:0;
      height: 50px;
      z-index: 20;
    }
  }
  .card-icon-btn,
  .edit {
    width: 50px;
    height: 50px;
    line-height: 50px;
    float: right;
    margin: 0;
    text-align: center;
    font-size: 14px;
    color: $almostblack;
    &:hover {
      color: $frostblue;
    }
    &.save-btn {
      right:60px;
    }
  }
  .accordion-section-content,
  .details {
    width: 100%;
    padding: 10px 20px;
    padding-top:0;
    margin: 0;
    color: $regulartext;
    .col {
      padding-left: 0px;
      padding-right: 0px;
      float: none;
      display: inline-block;
    }
    span.icon {
      min-width: 24px;
      height:18px;
      line-height: 18px;
      display: inline-block;
        i {
          line-height: 18px;
          color: $icongrey;
        }
        i.fa-trash {
          color: $red;
        }
        i.fa-check-square {
          color: $success;
        }
    }
    span.subtitle {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 13px;
      color: $almostblack;
    }

    p, .form-group {
      line-height: 18px;
      padding: 8px 0;
      margin-bottom: 0;
      input {
        width: calc(100% - 34px);
        &:focus {
          border: 1px solid $color_3;
        }
      }
    }
  }

  .linked-customer-item {
    border-bottom: 1px solid $bordercolor;
  }
  .attributes {
    a.green {
      background: #28a745;
    }
  }
}

.language_field {
  width: 100%;
}
.multi-input {
  position: relative;
  i {
    position: absolute;
    color: $color_4;
    top: 10px;
    right: 5px;
  }
}
.add_guest_label {
  font-size: 12px;
  font-family: $font_family_2;
  color: $color_15;
  text-transform: uppercase;
  padding: 5px 0;
  border-top: 1px solid $color_9;
  position: relative;
  i {
    float: right;
    color: #95b3c6;
    background: #efefef;
    font-size: 14px;
    padding: 3px;
    border-radius: 2px;
    top: -5px;
    margin: 0 0 0 2px;
  }
  i:hover {
    color: #fff;
    background: #0075be;
  }
}
.varify_details {
  width: 100%;
  float: left;
  padding: 5px 15px;
  margin: 0;

  p {
    padding: 0;
    margin: 0 0 3px 0;
    clear: both;
    font-size: 13px;
    color: $color_16;
    display: inline-block;
  }
  span {
    float: right;
    padding: 0;
    margin: 0;
  }
  a.link {
    width: 19px;
    height: 19px;
    padding: 0;
    margin: 0;
    display: inline-block;
    font-size: 12px;
    color: $color_17;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    text-align: center;
    line-height: 17px;
    &:hover {
      color: $color_18;
      border: 1px solid #0f7dc1;
    }
  }
  a.link.active {
    color: $color_18;
    border: 1px solid #0f7dc1;
  }
}
.additional_info {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0;
}
.accordion {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
.accordion-section-title {
  width: 100%;
  padding: 5px 40px 5px 15px;
  display: inline-block;
  border-top: 1px solid $bordercolor;
  position: relative;
  h3 {
    border-bottom:0;
  }
  /*
  background: @lightestgrey url(../images/plus.png) no-repeat right center;*/
  &:after {
    font-family: 'FontAwesome';
    content:"\f078";
    color: $icongrey;
    text-align: center;
    right:0;
    position: absolute;
    width: 50px;
    height:50px;
    line-height: 50px;
    display: block;
    top:0;
  }
  &.active {
    &:after {
      content: "\f077";
    }
  }
  &:hover {
    color: #fff;
  }

}
.accordion-section-title.active {
  background: #b5cad7 url(../images/minus.png) no-repeat right center;
  text-decoration: none;
}
.accordion-section-alt-title {
  width: 100%;
  padding: 5px 40px 5px 15px;
  display: inline-block;
  background: #fff url(../images/plus-fff.png) no-repeat right center;
  font-size: 12px;
  font-family: $font_family_2;
  color: $color_15;
  &:hover {
    color: $white;
  }
}
.accordion-section-alt-title.active {
  background: #fff url(../images/minus-fff.png) no-repeat right center;
  text-decoration: none;
}

.accordion-section-content {
  border-bottom: 1px solid $bordercolor;
}
.accordion-section-content
.authorise_main {
  p {
    i {
      padding: 0;
      margin: 0 5px 0 0;
      color: $color_4;
    }
  }
}
.authorise_info {
  width: 100%;
  float: left;
  padding: 0;
  margin: 0 0 5px 0;
  h5 {
    float: left;
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: $color_16;
    font-weight: 400;
    i {
      margin: 0 0 0 5px;
    }
  }
  .guest_link {
    height: 25px;
    float: right;
    padding: 0 15px;
    margin: 0;
    font-size: 12px;
    color: $color_8;
    line-height: 25px;
    border-radius: 4px;
    background: #0075be;
    &:hover {
      background: #b5cad7;
    }
  }
}
.notes_details {
  width: 100%;
  float: left;
  padding: 15px 0 0 0;
  margin: 0;
  .notes_block {
    padding: 13px 40px 13px 0;
    margin: 0;
    position: relative;
    border-bottom: 1px solid $bordercolor;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    span {
      font-size: 12px;
      font-family: $font_family_2;
    }
    .more_info {
      float: right;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 0;
      top: 16px;
      a {
        height: 14px;
        padding: 0 3px;
        margin: 0;
        display: block;
        line-height: 12px;
        font-size: 12px;
        color: $color_19;
        border: 1px solid #d1dfe7;
        border-radius: 2px;
      }
    }
    .busy_info {
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: $color_7;
      position: absolute;
      right: 24px;
      top: 16px;
    }
  }
}
.notes_field {
  width: 100%;
  height: 70px;
  padding: 8px;
  margin: 0;
  font-size: 14px;
  color: $color_4;
  background: #fffcf4;
  border: 1px solid #d1dfe7;
  border-radius: 4px;
}