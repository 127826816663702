ul.oprator_list {
  li {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    color: $color_5;
    line-height: 1.8em;
    span {
      float: right;
      padding: 0;
      margin: 0;
      img {
        margin: 0 0 0 2px;
      }
    }
    a {
      color: $color_5;
      &:hover {
        color: $color_3;
      }
    }
  }
}
.operator_details {
  width: 100%;
  #requests-bar {
    color: $white;
  }
  #requests-bar, #messages {
    padding-top: 79px;
    height: 100vh;
    border-right: 1px solid $leftpanelborder;
    position: relative;
    &#requests-bar {
      background: $leftpanelbg;
    }
  }
  background-image: -webkit-gradient(linear,
  left top,
  right top,
  color-stop(0, $background_color_1),
  color-stop(25%, $background_color_1),
  color-stop(25%, $color_8),
  color-stop(100%, $color_8)
  );
  background-image: -webkit-linear-gradient(
    left,
    $background_color_1,
    $background_color_1 25%,
    $color_8 25%,
    $color_8 100%
  );
  background-image: -moz-linear-gradient(
    left,
    $background_color_1,
    $background_color_1 25%,
    $color_8 25%,
    $color_8 100%
  );
  background-image: -ms-linear-gradient(
    left,
    $background_color_1,
    $background_color_1 25%,
    $color_8 25%,
    $color_8 100%
  );
}
.email-message-btn {
  margin-left: 40px;
}
