h1, h2, h3, h4, h5, h6 {
  outline: none !important;
  font-family: $font_family_1;
  margin: 15px 0;
  color: $regulartext;
}

h1 {font-size: 2.2em}
h2 {
  font-size: 20px;
  color: $almostblack;
}
h3 {font-size: 1.8em}
h4 {font-size: 1.5em}
h6 {
  font-size: 20px;
}

h6 {
    text-transform: uppercase;
    font-size: 13px;
}