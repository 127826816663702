@media (max-width : 1200px), screen and (max-device-width: 1200px) {
  #account {
    margin-left:0;
  }
}
@media (max-width : 767px), screen and (max-device-width: 767px) {

  .el-col[class*="el-col-offset-"] {
    margin-left:0;
  }
  .absolute-v-center {
      top: initial;
      position: relative;
      -webkit-transform: none;
      transform: none;
  }
  .account-settings {
    .input-line-height {
      line-height: initial;
    }
  }
}


/************ Main **************/
/************ iphone **************/
/************ Responsive **************/
/*@media screen and (max-height: 450px) {
.sidenav { padding-top:15px;}
.sidenav a { font-size:18px;}
}*/
@media (max-width:1200px) {
  .container {
    width: 96%;
    margin: 0 auto;
    padding: 0;
  }
}
@media (max-width:1048px) {
  table.request_list td.name {
      max-width: 110px;
  }
}

@media (max-width: 990px) {
  .operator_details {
    .cols9 {
      width: 70%;
    }
    .cols3 {
      width: 25%;
    }
  }
  .col {
        padding-left: 5px;
        padding-right: 5px;
    }
    left_bar {
        width: 90%;
        padding: 25px 0;
      overflow: hidden;
    }
    table.request_list td.name {
        max-width: 80px;
    }
}
@media (max-width: 767px) {
  html {
    -webkit-text-size-adjust: none;
  }
  .grid {
    display: inline;
  }
  .topbar {
    padding-bottom: 8px;
  }
  .welcome_user {
    position: absolute;
    right: 15px;
    top: 5px;
  }
  .operator_details {
    padding-top: 136px;
    .cols9 {
      width: 100%;
    }
    .cols3 {
      width: 100%;
    }
    .container-fluid {
      padding-left: 0px;
    }
  }
  .search_info_alt {
    width: 100%;;
  }
  .filter_info {
    display: none;
  }
  table.request_list td.name {
      max-width: 140px;
  } 
  .sidenav {
    top: 144px;
  }
  .right_details {
    border-left: none;
  }
  .right_space {
    padding-left: 0;
    padding-right: 0;
  }
  .cols12 {
    width: 100%;
  }
  .cols11 {
    width: 100%;
  }
  .cols10 {
    width: 100%;
  }
  .cols9 {
    width: 100%;
  }
  .cols8 {
    width: 100%;
  }
  .cols7 {
    width: 100%;
  }
  .cols6 {
    width: 100%;
  }
  .cols5 {
    width: 100%;
  }
  .cols4 {
    width: 100%;
  }
  .cols3 {
    width: 100%;
  }
  .cols2 {
    width: 100%;
  }
  .cols1 {
    width: 100%;
  }
  .col {
      padding-left: 0;
      padding-right: 0;
  }
  .login-form {
    width: 100%;
  }
  .operator_details {
    .cols-6 {
    padding-left: 0 !important;
    }
  } 
  #register-form {
    form {
      button {
        width: 100%;
        }
      }
}
  
}
@media (max-width:600px) {
  html {
    -webkit-text-size-adjust: none;
  }
  .welcome_user {
    .user_link {
      span {
        display: none;
      }
    }
  }
}
