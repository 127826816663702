.grid {
  margin-left: -15px;
  margin-right: -15px;
  height: 100%  !important;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.col {
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}
.cols12 {
  width: 100%;
}
.cols11 {
  width: 91.66666667%;
}
.cols10 {
  width: 83.33333333%;
}
.cols9 {
  width: 75%;
}
.cols8 {
  width: 66.66666667%;
}
.cols7 {
  width: 58.33333333%;
}
.cols6 {
  width: 50%;
}
.cols6-a {
  width: 49%;
}
.cols5 {
  width: 41.66666667%;
}
.cols5-a {
  width: 40%;
}
.cols4 {
  width: 33.33333333%;
}
.cols3 {
  width: 25%;
}
.cols2 {
  width: 16.66666667%;
}
.cols1 {
  width: 8.33333333%;
}