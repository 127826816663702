#change-password-form {
  padding: 0;
  .grid {
    display: inline;
  }
  .logo {
    width: 100%;
    text-align: center;
    padding: 40px 0;
    img {
      width: 40px;
    }
  }
  p {
    text-align: center;
    padding: 25px 0;
    line-height: 1.8em;
  }
  h2 {text-align: center; line-height: 1.3em;}
  form {
    padding: 15px 20px;
    margin: 0 auto;
    label {
      font-family: $font_family_2;
      font-size: 13px;
      color: $color_15;
      padding: 0 10px;
    }
    input {
      padding: 8px 10px;
      width: 100%;
      font-family: $font_family_2;
      font-size: 15px;
      color: $color_2;
      margin: 0 0 10px 0;
      border-radius: 3px;
      height: 40px;
      &:checkbox {
        width: 20px !important;
        padding: 0 10px 0 0;
        background: red;
      }
    }
    .button {
      text-align: center;
    }
    button {
      margin: 25px auto;
      padding: 8px;
      width: 40%;
      font-family: $font_family_2;
      font-size: 15px;
      color: $color_8;
      background: $background_color_2;
      border: 0;
      border-radius: 4px;
      &:hover {
        background: $color_21;
      }
    }
    .form_field {
      position: relative;
      i {
        position: absolute;
        color: $color_21;
        font-size: 21px;
        top: 28px;
        right: 25px;
      }
    }
    .guest_link_form {
      float: right;
      padding: 6px 20px;
      margin: 10px 0 0 0;
      font-size: 12px;
      color: $color_8;
      line-height: 25px;
      border-radius: 4px;
      background: $color_3;
      &:hover {
        background: $color_20;
      }
    }
  }

}


.checkbox {
  padding: 10px 0;
  label {
    font-size: 16px !important;
    display: inline-block;
    color: $white;
    cursor: pointer;
    position: relative;
    span {
      display: inline-block;
      position: relative;
      background-color: transparent;
      width: 30px;
      height: 30px;
      transform-origin: center;
      border: 2px solid $color_3;
      border-radius: 50%;
      vertical-align: -6px;
      margin-right: 10px;
      &:before {
        content: "";
        width: 0px;
        height: 4px;
        border-radius: 2px;
        background: $white;
        position: absolute;
        transform: rotate(45deg);
        top: 13px;
        left: 9px;
        transition: width 50ms ease 50ms;
        transform-origin: 0% 0%;
      }
      &:after {
        content: "";
        width: 0;
        height: 2px;
        background: $white;
        position: absolute;
        transform: rotate(305deg);
        top: 16px;
        left: 10px;
        transition: width 50ms ease;
        transform-origin: 0% 0%;
      }
    }
    // Time to add some life to it

    &:hover {
      span {
        &:before {
          width: 5px;
          transition: width 100ms ease;
        }

        &:after {
          width: 10px;
          transition: width 150ms ease 100ms;
        }
      }
    }
  }

  input[type="checkbox"] {
    display: none; // hide the system checkbox

    // Let's add some effects after the checkbox is checked

    &:checked {
      + label {
        span {
          background-color: $white;
          transform: scale(1.25); // enlarge the box

          &:after {
            width: 10px;
            background: #1790b5;
            transition: width 150ms ease 100ms; // enlarge the tick
          }

          &:before {
            width: 5px;
            background: #1790b5;
            transition: width 150ms ease 100ms; // enlarge the tick
          }
        }

        &:hover {
          span {
            background-color: $white;
            transform: scale(1.25);

            &:after {
              width: 10px;
              background: #1790b5;
              transition: width 150ms ease 100ms;
            }

            &:before {
              width: 5px;
              background: #1790b5;
              transition: width 150ms ease 100ms;
            }
          }
        }
      }
    }
  }
}
.height-100vh {
  height: 100vh;
  position: relative;
}


#login {
  overflow-y: hidden;
  .el-col {
  }
  #login-aside {
    padding: 20px 36px;
  }
  .el-button {
    width: 100%;
    font-size: 16px;
  }
}
.box {
  width: 100%;
  left:0;
  right:0;
  .el-card__header {
    border-bottom: 0;
    h2 {
      line-height: 44px;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 16px;
    }
  }
  .el-card {
    border:0;
    box-shadow: none;
    color: $almostblack;
  }
}
form {
  label {
    color: $almostblack;
    font-family: $font_family_2;
  }
}